<template>
  <b-card>
      <b-row>
        <b-col sm="6" id="wrapper-company-name">
          <b-tooltip target="wrapper-company-name" noninteractive>Коротка назва використовується для відображення у мобільному додатку</b-tooltip>
          <b-form-group label="Коротка назва" label-for="account-username">
            <b-form-input v-model="myCompany.titleCustom" placeholder="Коротка назва" name="company-name" id="company-name"
                          readonly/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Айді компанії" label-for="account-username">
            <b-form-input v-model="myCompany.idCompany" placeholder="Айді компанії" name="company-id"
                          readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Повна назва" label-for="account-username">
            <b-form-input v-model="myCompany.titleFull" placeholder="Повна назва" name="company-full-name" id="company-full-name"
                          readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Юридична адреса" label-for="account-username">
            <b-form-input v-model="myCompany.addressLegal" placeholder="Юридична адреса" name="company-legal-adress"
                          readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Код ЄДРПОУ" label-for="account-username">
            <b-form-input v-model="myCompany.edrpou" placeholder="Код ЄДРПОУ" name="company-edrpou"
                          readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="ІПН" label-for="account-username">
            <b-form-input v-model="myCompany.ipn" placeholder="ІПН" name="company-ipn"
                          readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="IBAN рахунок" label-for="account-username">
            <b-form-input v-model="myCompany.iban + ', ' + myCompany.ibanBank" placeholder="IBAN рахунок" name="company-iban"
                          readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="МФО" label-for="account-username">
            <b-form-input v-model="myCompany.mfo" placeholder="МФО" name="company-mfo"
                          readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Телефон" label-for="account-username">
            <b-form-input v-model="myCompany.phoneContact" placeholder="Телефон" name="company-phone"
                          readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Електрона пошта" label-for="account-username">
            <b-form-input v-model="myCompany.email" placeholder="Електрона пошта" name="company-email"
                          readonly />
          </b-form-group>
        </b-col>
      </b-row>
</b-card>
</template>

<script>
import {
    BFormFile, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTable, BTooltip, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from "@/libs/axios";

export default {
  components: {
    BButton,
    BCard,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTable,
    BTooltip,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      myCompany: {},
    }
  },
  created() {
    this.getCompany();
    this.$store.dispatch('businessSettings/fetchCommonSettings')
  },
  mounted() {
    this.fixNullResponseData();
  },
  methods: {
    getCompany: async function () {
      return await axiosIns
          .get('/business/information')
          .then(response => {
            this.myCompany = {...response.data};

            return response.data;
          })
          .catch(() => {
            return false
          });
    },
    fixNullResponseData() {
      if (!this.myCompany.iban && !this.myCompany.ibanBank) {
        this.myCompany.iban = "";
        this.myCompany.ibanBank = "";
      }
    },
  },
}
</script>
